import { useQuery } from "@apollo/client";
import { ALL_REFS } from "../queries";
import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import NotifyRef from "./NewNotify";
import NewNotify from "./NewNotify";


const ReferenceList = ({ token, setNotification }: { token: string | null, setNotification: (message: string, type: 'success' | 'error') => void }) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "참고자료"

        if (location.pathname === "/references") {
            setMessage(
                `트랩위키 각각의 페이지 하단에 참고자료를 업데이트하고 있습니다.\n \n이 페이지에서는 어느 한 주제에 속하지 않는 참고자료를 소개합니다.`
            )
            setMessageType("success");
            setTimeout(() => {
                setMessage(null)
                setMessageType(null)
            }, 6000)
        }
    }, [document.title]);

    const referenceResult = useQuery(ALL_REFS)

    const padding = {
        padding: 5,
        textDecoration: "none",
        color: "white"
    }

    const maxLength = 200;

    return (<div style={{ margin: 10 }}>
                <NewNotify message={message} messageType={messageType} />
        <div>
            <br/>
        </div>
        {referenceResult.data?.allReferences.map((ref: any) => {

            return <div key={ref.id} style={{   display: 'inline-block', 
                borderStyle: "inset", 
                marginBottom: 20, 
                padding: 10, 
                maxWidth: window.innerWidth > 700 ? '90%' : '90%', // Responsive width
                marginRight: window.innerWidth > 700 ? '2%' : '2%' }}>
                    <div style={{marginLeft: 5}}>
                <h4>{ref.title + " | " + ref.author}</h4>
                <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word',maxWidth: '35ch' }}>
                {ref.description.length > maxLength ? ref.description.substring(0, maxLength) + "..."  : ref.description }
                </div>
                <br />
                <a href={ref.link} style={{ color: "#7c73e6", textDecoration: "none" }}>{ref.linkTitle}{`(${ref.link?.slice(0, 15)}···)`}</a>
                <br/>
                <br/>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                <div>{new Date(Number(ref.createdAt)).toLocaleString('ko-KR')}</div>
                {<Link to={`/references/${ref.id}`} style={padding}>
    
             
          
                <Button variant="contained" >자세히</Button>
                    {/* <RefStyledBox>{ref.author} {ref.title}</RefStyledBox> */}
                </Link>}
                </div>
                </div>
            </div>
        })

        }</div>)
}


export default ReferenceList;